<template>
  <div class="content-full">
    <div>
      <h3 class="mb-3">Step 3: Confirmation</h3>
    </div>
    <div class="mt-5">
      <h3>Asset Details</h3>
      <div class="flex flex-wrap flex-row">
        <div class="half">
          <h5 class="label">Asset Name</h5>
          <div class="value">{{ holding.planName }}</div>
        </div>
        <template v-for="field in Object.values(holding.filteredExtendedDetails)" :key="field.id">
          <div class="half" v-if="!['name', 'planName'].includes(field.id)">
            <h5 class="label">{{ matchLabelFromField(field.id) }}</h5>
            <div class="value">{{ matchValueFromField(field.id, field.value) }}</div>
          </div>
        </template>
      </div>
    </div>

    <spacer-h/>

    <SMHHoldingsTable :editable="false"/>

    <div class="flex justify-content-end my-3">
      <Button class="clarity-btn clarity-gradient-orange" @click="$router.push('/investments/selfmanaged')">
        Go to Self Managed Holdings
      </Button>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, watch, ref, onMounted} from "vue";
import SpacerH from "@/components/common/layout/SpacerH";
import SMHHoldingsTable from "@/components/selfManaged/SMHHoldingsTable";

export default {
  name: "StepThreeConfirmation",
  components: {SpacerH, SMHHoldingsTable},
  setup() {
    const store = useStore()
    const ownership = ref()
    const reminderEnabled = ref(false)
    const mode = computed(() => store.state.selfManaged.mode)

    const split = computed(() => store.getters.getPercentageSplit)

    const product = computed(() => store.getters.getSelectedProductObject)

    const getOwnershipPresentation = (array) => {
      let rtn = ''
      array.forEach(person => {
        rtn += `${person.name} (${person.percentage}%) `
      })
      ownership.value = rtn
    }

    const toggleReminderAlert = () => {
      reminderEnabled.value = !reminderEnabled.value
    }

    watch(split, newSplit => {
      getOwnershipPresentation(newSplit)
    })

    onMounted(() => {
      getOwnershipPresentation(split.value)
    })

    const matchLabelFromField = (field) => {
      const staticArray = [
        {value: 'planName', label: 'Asset Name'},
      ]

      const result =  staticArray.find(entry => entry.value === field)

      const firstLetter = field.charAt(0).toUpperCase()
      const capitalizedWord = firstLetter + field.slice(1)

      return result ? result.label : capitalizedWord.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
    }

    const matchValueFromField = (field, value) => {
      let rtnString = ''
      switch (true) {
        case field === 'ownership':
          value.forEach(person => {
            rtnString += `${ store.getters.personFromUuid(person.owner).name} (${person.ownership.toFixed(2) ?? person.percentage.toFixed(2)} %) `
          })
          break
        case value instanceof Date:
          rtnString = value.toLocaleDateString('en-GB')
          break
        default:
          rtnString = value
      }
      return rtnString

    }

    const holding = computed(() => {
        if(mode.value === 'liability') {
            if (store.getters.isEditingExistingValuationHolding) {
                return store.getters.selfManagedLiabilities[store.getters.isEditingExistingValuationHoldingIndex]
            } else {
                return store.getters.selfManagedLiabilities[store.getters.selfManagedLiabilities.length - 1]
            }
        }else{
            if (store.getters.isEditingExistingValuationHolding) {
                return store.getters.selfManagedHoldings[store.getters.isEditingExistingValuationHoldingIndex]
            } else {
                return store.getters.selfManagedHoldings[store.getters.selfManagedHoldings.length - 1]
            }
        }
    });

    onMounted(() => {
        store.commit('setPolicyLoading', true)
        store.commit('setPortfolioLoading', true)
        store.dispatch('getPortfolioOptions')
        store.dispatch('getPolicyOptions')
    });

    return {
      holding,
      mode,
      ownership,
      product,
      reminderEnabled,
      toggleReminderAlert,
      matchLabelFromField,
      matchValueFromField
    }
  }
}
</script>

<style scoped lang="scss">
.content-full {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

.body {
  font-size: 18px;
}

.row {
  display: flex;
  align-items: baseline;
  padding: 15px 0;
}

.half {
  display: flex;
  width: 50%;

  .label {
    width: 20%;
  }

  .value {
    width: 80%;
  }
}

.label {
  color: var(--clarity-blue);
  width: 10%;
}

.value {
  font-size: 18px;
  width: 90%;

  &.reminder {
    width: 10%;
  }
}

.selected-option {
  font-weight: bold;
}

.unselected {
  color: var(--clarity-grey);
}

button {
  min-width: 220px;
  justify-content: center;
  padding: 15px 45px;
}

@media only screen and (max-width: 1023px) {
  .half {
    width: 100%;
  }
}
</style>
